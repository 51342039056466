define("builder/serializers/page-meta", ["exports", "@ember-data/serializer/rest", "builder/core/enumerators/variant-names", "builder/core/convertors"], function (_exports, _rest, _variantNames, _convertors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend({
    /**
     * @inheritdoc
     */
    primaryKey: 'Id',

    /**
     * Since we don't pluralize page-meta in server response, we have to explicitly return model name
     * ex. for single and plural page-meta responses model must be page-meta
     *
     * @inheritdoc
     */
    modelNameFromPayloadKey() {
      return 'page-meta';
    },

    /**
     * @inheritdoc
     */
    serialize(...args) {
      const json = this._super(...args);

      const structure = json.Structure;

      if (structure) {
        for (let i = 0; i < structure.length; i++) {
          if (typeof structure[i].Params !== 'undefined') {
            if (structure[i].Params) {
              structure[i].Params = (0, _convertors.convertStringParamsToObject)(structure[i].Params);
            }
          }

          if (structure[i]['variant-name']) {
            delete structure[i]['variant-name'];
          }
        }
      }

      return json;
    },

    /**
     * @inheritdoc
     */
    normalize(model, hash, ...args) {
      const structure = hash.Structure;

      if (structure) {
        for (let i = 0; i < structure.length; i++) {
          if (structure[i].Params) {
            structure[i].Params = (0, _convertors.convertObjectToStringParams)(structure[i].Params);
          }

          structure[i]['variant-name'] = _variantNames.PAGE_META_NAME;
        }
      }

      return this._super(model, hash, ...args);
    }

  });

  _exports.default = _default;
});